import { useEffect, useState } from "react";
import questionsJson from "../../../questions.json";
import { defineDefaultValue } from "../../../utils/defineDefaultValue";
import InputComponent from "./InputComponent/InputComponent";
import clsx from "clsx";
import {
  isSubmitButtonDisabled,
  isUserInputDigits,
} from "../../../utils/disableButtonLogic";
import GetNewUserId from "./GetNewUserID/GetNewUserId";
const queryParameters = new URLSearchParams(window.location.search);
const round = queryParameters.get("round");

let eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
let eventer = window[eventMethod];
let messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

const Question = ({ newUserId, apiMessage, setApiMessage, setNewUserId }) => {
  const currentQuestion = questionsJson.challenge[`set${round}`];
  const [userID, setUserID] = useState("");
  const [successUserId, setSuccessUserId] = useState("");
  const [isErrorStatusCode, setIsErrorStatusCode] = useState(false);
  const [loading, setIsLoading] = useState(false);

  const onSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    fetch(
      "https://bvh8nqmuxa.execute-api.eu-central-1.amazonaws.com/dev/answer",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: newUserId ? newUserId.toString() : userID.toString(),
          questionId: parseInt(round),
          answer: { ...formValues },
          generatedUserId: newUserId !== "" ? true : false,
        }),
      }
    )
      .then((response) => {
        if (response.status > 201) {
          setIsErrorStatusCode(true);
        }
        return response.text();
      })
      .then((data) => {
        setIsLoading(false);
        setApiMessage(JSON.parse(data).message);
        setSuccessUserId(JSON.parse(data).userId);
      })
      .catch((e) => {
        setIsLoading(false);
        setApiMessage(JSON.parse(e));
      });
  };

  const [formValues, setFormValues] = useState(
    currentQuestion.q.reduce(
      (acc, input) => ({
        ...acc,
        [input.id]:
          defineDefaultValue(
            input.defaultValue,
            input.inputMax,
            input.inputMin
          ) || "",
      }),
      {}
    )
  );

  const [formInteracted, setFormInteracted] = useState(
    currentQuestion.q.reduce(
      (acc, input) => ({
        ...acc,
        [input.id]: false,
      }),
      {}
    )
  );

  const [isFormValid, setIsFormValid] = useState(false);

  const handleInputChange = (event) => {
    const { value, id } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setFormInteracted({ ...formInteracted, [id]: true });
  };

  useEffect(() => {
    Object.values(formInteracted).forEach((element) => {
      if (!element) {
        setIsFormValid(false);
        return;
      }
      setIsFormValid(true);
    });
  }, [formInteracted]);

  // Listen to message from child window
  eventer(
    messageEvent,
    function (e) {
      if (e?.data?.lat && e?.data?.lng && round === "4") {
        setFormValues((prevState) => ({
          ...prevState,
          1: [e.data.lat, e.data.lng],
        }));
        setFormInteracted({ ...formInteracted, 1: true });
      }
    },
    false
  );

  const onResetFormValues = () => {
    setApiMessage("");
  };

  const copyToClipboardFunction = async () => {
    await navigator.clipboard.writeText(successUserId);
    alert(
      "Deine Ticketnummer " +
        successUserId +
        " wurde in die Zwischenablage kopiert. Speichere sie dir an einem sicheren Ort!"
    );
  };

  return (
    <form
      onSubmit={onSubmit}
      className={clsx("formDefault", loading && "blurBackground")}
    >
      {currentQuestion.q.map((question, index) => {
        return (
          <div key={index}>
            <p className="question">{question.text}</p>
            {question.note && (
              <span className="question note">
                <i>{question.note}</i>
              </span>
            )}
            <InputComponent
              index={index}
              value={formValues[index + 1]}
              onChange={handleInputChange}
            />
          </div>
        );
      })}
      <br />
      <hr />
      {apiMessage !== "" && (
        <>
          <div className="feedbackWrapper">
            <p>{apiMessage}</p>
          </div>
          <div className="next-btn">
            {isErrorStatusCode ? (
              <button onClick={onResetFormValues} className="btn">
                zurück
              </button>
            ) : (
              <>
                <p className="paragraph finish"></p>
                <div className="input-ticket">
                  <input
                    type="button"
                    id="text"
                    className="ticket success"
                    name="userID"
                    value={successUserId}
                    onClick={copyToClipboardFunction}
                  />
                </div>
                <p className="note finish">
                  Bewahre die Nummer gut auf, um später weitere Punkte damit zu
                  sammeln! Mit einem Klick auf das Ticket kannst Du die Nummer
                  in die Zwischenablage kopieren.
                </p>
              </>
            )}
          </div>
        </>
      )}
      {apiMessage === "" && (
        <>
          <p className="question" id="losnummer">
            Gib vor dem Absenden noch Deine Ticketnummer ein.
          </p>
          <span className="question note">
            <i>
              Falls Du noch keine Nummer hast, kannst Du dir über den Button
              eine neue generieren lassen.
            </i>
          </span>

          <div className="input-ticket">
            <input
              disabled={newUserId !== ""}
              type="text"
              id="text"
              className="ticket"
              name="userID"
              value={newUserId || userID}
              onChange={(e) => setUserID(e.target.value)}
            />
            <GetNewUserId
              setNewUserId={setNewUserId}
              setIsLoading={setIsLoading}
            />
          </div>
          {loading && (
            <div className="loadingState">
              <div className="loading-dots">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )}
          <div className="next-btn">
            <button
              className={clsx(
                "btn",
                (isSubmitButtonDisabled(newUserId, isUserInputDigits(userID)) ||
                  !isFormValid) &&
                  "btn-disabled"
              )}
              type="submit"
              disabled={
                isSubmitButtonDisabled(newUserId, isUserInputDigits(userID)) ||
                !isFormValid
              }
            >
              Absenden
            </button>
          </div>
        </>
      )}
    </form>
  );
};

export default Question;

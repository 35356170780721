export const isUserInputDigits = (input) => {
    if (input.length !== 6) {
      return false;
    }
    let isnum = /^\d+$/.test(input);
    return isnum;
  };

  export const isSubmitButtonDisabled = (newUserId, isUserInputOnlyDigits) => {
    if (newUserId !== "") {
      return false;
    }
    if (isUserInputOnlyDigits) {
      return false;
    }
    return true;
  };
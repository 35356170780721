import React, { useState, useEffect } from 'react';
import Introduction from "./components/Introduction/Introduction";
import "./App.css";

function App() {
  const size = useComponentSize();
  function useComponentSize() {
    const [componentSize, setComponentSize] = useState({
      width: undefined,
      height: undefined,
    });
    useEffect(() => {
      function handleResize() {
        setComponentSize({
          width: window.innerWidth,
          height: document.body.offsetHeight,
        });
      }
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => window.removeEventListener("resize", handleResize);
    }, []);
    return componentSize;
  }

  window.parent.postMessage(size.height, "*");

  return (
    <div className="App">
      <header className="App-header">
        <div className="bg_box" id="questions">  
         <Introduction />   
        </div>
      </header>
    </div>
  );
}

console.log("\u2500\u2584\u2580\u2500\u2584\u2580\r\n\u2500\u2500\u2580\u2500\u2500\u2580\r\n\u2588\u2580\u2580\u2580\u2580\u2580\u2588\u2584\r\n\u2588\u2591\u2591\u2591\u2591\u2591\u2588\u2500\u2588\r\n\u2580\u2584\u2584\u2584\u2584\u2584\u2580\u2580\r\n\r\n\r\nSucht da jemand nach der richtigen Lösung? Hier wirst du sie nicht finden...")

export default App;

import React from "react";

export default function GetNewUserId({ setNewUserId, setIsLoading }) {
  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    fetch(
      "https://bvh8nqmuxa.execute-api.eu-central-1.amazonaws.com/dev/user",
      {
        method: "GET",
        headers: {
          "Content-Type": "aplication/json",
        },
      }
    )
      .then((response) => {
        return response.text();
      })
      .then((response) => {
        setIsLoading(false);
        setNewUserId(JSON.parse(response).newUserID);
      })
      .catch((e) => {
        setIsLoading(false);
        console.log("error while creating new userId", e);
      });
  };

  return (
    <button className="btn-ticket" type="submit" onClick={handleSubmit}>
      <img src="reload.png" className="refresh" alt="reload" /><div className="ticket-text">neu</div>
    </button>
  );
}

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Points from "./components/Points/Points";
import Legal from "./components/Legal/Legal";


const router = createBrowserRouter([
  {
    path: "/points",
    element: <Points />
  },
  {
    path: "/datenschutz",
    element: <Legal />,
  },
  {
    path: "/gewinnspiel",
    element: <Legal />,
  },
  {
    path: "*",
    element: <App />,
  }
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();

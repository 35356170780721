import clsx from "clsx";
import { useState } from "react";
import questionsJson from "../../questions.json";
import { checkIfQuestionIsPlayable } from "../../utils/checkIfQuestionIsPlayable";
import { isUserInputDigits } from "../../utils/disableButtonLogic";
import "./points.css";

export default function Points() {
  const [formValues, setFormValues] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [apiResponse, setApiResponse] = useState([]);

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    fetch(
      `https://bvh8nqmuxa.execute-api.eu-central-1.amazonaws.com/dev/score/${formValues}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "aplication/json",
        },
      }
    )
      .then((response) => {
        return response.text();
      })
      .then((response) => {
        setLoading(false);
        if (JSON.parse(response).answers.length === 0) {
          console.log("test");
          setError(
            "Für diese Ticketnummer sind leider keine Ergebnisse verfügbar"
          );
        } else {
          setApiResponse(JSON.parse(response).answers);
        }
      })
      .catch((e) => {
        setLoading(false);
        setError(e);
        console.log("error: ", e);
      });
  };

  const constructArrayThree = (arrayTwo) => {
    const arrayThree = [];
    for (let i = 1; i <= 10; i++) {
      // Check if there is an object in arrayTwo with the current questionId
      const objectWithQuestionId = arrayTwo.find((obj) => obj.questionId === i);

      if (objectWithQuestionId) {
        arrayThree[i] = objectWithQuestionId;
      } else {
        arrayThree[i] = {
          questionId: i,
          answer: null,
          userId: formValues,
        };
      }
    }
    arrayThree.sort((a, b) => a.questionId - b.questionId);

    return arrayThree;
  };

  const renderColumns = () => {
    const questionsArray = Object.values(questionsJson?.challenge);
    const formattedArray = constructArrayThree(apiResponse);
    let startDate;
    let isQuestionAlreadyPlayable;
    const columns = [];
    for (let i = 0; i < formattedArray.length - 1; i++) {
      startDate = questionsArray[i].start_date;
      isQuestionAlreadyPlayable = checkIfQuestionIsPlayable(startDate);
      columns.push(
        renderColumn(i + 1, formattedArray[i], isQuestionAlreadyPlayable)
      );
    }
    return columns;
  };

  // render a column with a number and an icon
  const renderColumn = (number, answer, isQuestionAlreadyPlayable) => {
    let iconClass;
    let alt;
    let url;
    if (!isQuestionAlreadyPlayable) {
      return (
        <div className="column grey" key={number}>
          <div className="number">{number}</div>
        </div>
      );
    }
    if (isQuestionAlreadyPlayable && answer?.answer === null) {
      return (
        <div className="column" key={number}>
          <div className="number">{number}</div>
        </div>
      );
    }
    if (answer?.correct === undefined || answer.correct === null) {
      iconClass = "icont-inEvaluation";
      alt = "In Prüfung";
      url = "../img/icons/hourglass.png";
    } else if (!answer?.correct) {
      iconClass = "icon-false";
      url = "../img/icons/cross.png";
      alt = "Falsch";
    } else {
      iconClass = "icon-true";
      url = "../img/icons/tick.png";
      alt = "Richtig";
    }
    return (
      <div className="column" key={number}>
        <div className="number">{number}</div>
        {url && <img src={url} alt={alt} className={iconClass} />}
      </div>
    );
  };

  return (
    <div className="App">
      <header className="App-header">
        <div
          className={clsx("bg_box", loading && "blurBackground")}
          id="questions"
        >
          {apiResponse?.length === 0 && (
            <>
              <h1>Ergebnisse</h1>
              <p className="paragraph">
                Auf dieser Seite kannst du dein bisher erspieltes Ergebnis
                einsehen. Dazu brauchst du eine Ticketnummer, mit der du schon
                fleißig Fragen beantwortet hast.
              </p>
              <p className="paragraph">
                Gib hier bitte deine Ticketnummer ein, um deine Ergebnisse
                anzeigen zu lassen:
              </p>

              <form onSubmit={onSubmit}>
                <div className="input-ticket">
                  <input
                    value={formValues}
                    onChange={(e) => setFormValues(e.target.value)}
                    type="text"
                    id="text"
                    className="ticket"
                    name="userID"
                  />
                  <button
                    className={clsx(
                      "btn-ticket",
                      !isUserInputDigits(formValues) && "btn-ticket-disabled"
                    )}
                    type="submit"
                    disabled={!isUserInputDigits(formValues)}
                  >
                    <div className="ticket-text-request">Absenden</div>
                  </button>
                </div>
              </form>

              {loading && (
                <div className="loadingState">
                  <div className="loading-dots">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              )}

              <p className="paragraph"></p>
            </>
          )}
          {apiResponse?.length > 0 && error?.length === 0 && (
            <div>
              <h1>Ergebnisse</h1>
              <p className="paragraph">
                In der angezeigten Tabelle siehst du, welche Frage-Runden du
                bereits beantwortet hast, und den aktuellen Status der
                Auswertung.
              </p>
              <div className="row">{renderColumns()}</div>
              <p className="paragraph">
                Richtig beantwortete Fragen erkennst du am grünen Häkchen,
                falsche Antworten an dem roten X. Fragen, die von uns noch
                manuell überprüft werden müssen, sind mit einer kleinen Sanduhr
                gekennzeichnet. Komme gerne später wieder, um den Status zu
                überprüfen. Fragen ohne Symbol sind entweder noch nicht gespielt
                - oder noch nicht spielbar, wenn sie ausgegraut sind.
              </p>
              <p className="paragraph"></p>
            </div>
          )}
          {apiResponse?.length === 0 && error?.length > 0 && (
            <div>
              <p className="error">{error}</p>
            </div>
          )}
        </div>
      </header>
    </div>
  );
}

import { useState } from "react";
import questionsJson from "../../questions.json";
import { checkIfQuestionIsPlayable } from "../../utils/checkIfQuestionIsPlayable";
import Question from "./Question/Question";
const queryParameters = new URLSearchParams(window.location.search);
const round = queryParameters.get("round");

const Introduction = () => {
  const questionObject = questionsJson.challenge[`set${round}`];
  const [newUserId, setNewUserId] = useState("");
  const [apiMessage, setApiMessage] = useState("");
  let isQuestionAlreadyPlayable;

  if(questionObject) {
    isQuestionAlreadyPlayable = checkIfQuestionIsPlayable(questionObject?.start_date);
  }

  if (!questionObject) {
    return <>
    <h1>Hoppla!</h1>
    <p className="paragraph">Die aufgerufene Seite existiert nicht. Wir fragen uns, wie Du hier gelandet bist... 🤔</p>
    <p className="paragraph">Willst Du vielleicht Deine Recherchekünste unter Beweis stellen? Dann komm gerne über <a href="https://dpa-factify.com/learning-topic/9ae76378-691f-471f-9b7c-d013a46a5e3b" target="_blank" rel="noreferrer">dpa-Factify</a> zurück!</p>
    <p className="paragraph"></p>
    </>
  }

  if (!isQuestionAlreadyPlayable){
    return <>
    <h1>Hoppla!</h1>
    <p className="paragraph">Die Frage ist noch gar nicht freigeschaltet.</p>
    <p className="paragraph">Komm zu einem späteren Zeitpunkt über <a href="https://dpa-factify.com/learning-topic/9ae76378-691f-471f-9b7c-d013a46a5e3b" target="_blank" rel="noreferrer">dpa-Factify</a> zurück!</p>
    <p className="paragraph"></p>
    </>
  }

  return (
    <>
          {questionObject.links && (
        <>
          <div className="links">Nützliche Links:
            <span className="note">
              <ul>
                {questionObject.links.map((element, index) => {
                  return (
                    <li key={index}><a href={element.url} target="_blank" rel="noreferrer" >{element.title}</a></li>
                  )
                })}
              </ul>
            </span>
          </div>
        </>
      )}
      <h1>Runde {round}</h1>
      <p className="paragraph">{questionObject.text}</p>
      {questionObject.image && (
        <>
          <div className="image-box">
            <img
              className="img-full"
              src={questionObject.image[0].url}
              alt={questionObject.image[0].alt}
              height={questionObject.image[0].height}
            ></img>
            <p className="img-txt">{questionObject.image[0].alt}</p>
          </div>
        </>
      )}
      {questionObject.video && (
        <iframe
          className="youtube"
          src={questionObject.video}
          title="YouTube video player"
          allow="accelerometer; clipboard-write; encrypted-media; web-share"
          allowFullScreen
        ></iframe>
      )}
      <Question
        newUserId={newUserId}
        apiMessage={apiMessage}
        setApiMessage={setApiMessage}
        setNewUserId={setNewUserId}
      />
    </>
  );
};

export default Introduction;

import questionsJson from "../../../../questions.json";
const queryParameters = new URLSearchParams(window.location.search);
const round = queryParameters.get("round");

const InputComponent = (props) => {
  const currentQuestion = questionsJson.challenge[`set${round}`].q[props.index];


  switch (currentQuestion.inputType) {
    case "timepicker":
      return (
        <>
          <input
            type="time"
            className="time"
            id={currentQuestion.id}
            name="timepicker"
            value={props.value}
            onChange={props.onChange}
          />
        </>
      );
    case "date":
      return (
        <>
          <p className="input">
            <input
              type="date"
              id={currentQuestion.id}
              name="date"
              className="date"
              value={props.value}
              onChange={props.onChange}
            />
          </p>
        </>
      );
    case "range":
      return (
        <>
          <p className="input">
            <input
              type="range"
              id={currentQuestion.id}
              name="range"
              className="range"
              min={currentQuestion.inputMin}
              max={currentQuestion.inputMax}
              onChange={props.onChange}
              defaultValue={
                (currentQuestion.inputMin + currentQuestion.inputMax) / 2
              }
            />{" "}
            <output className="output" id="output">
              {parseInt(props.value)}
            </output>{" "}
            {currentQuestion.units}
          </p>
        </>
      );
    case "text":
      return (
        <>
          <p className="input">
            <input
              type="text"
              id={currentQuestion.id}
              className="text"
              value={props.value}
              onChange={props.onChange}
              name="text"
            />
          </p>
        </>
      );
    case "textarea":
      return (
        <>
          <p className="input">
            <textarea
              id={currentQuestion.id}
              className="textarea"
              value={props.value}
              onChange={props.onChange}
              name="textarea"
            />
          </p>
        </>
      );
    case "geoguesser":
      return (
        <>
          <iframe
            title="Test"
            id="iframeMap"
            src={process.env.NODE_ENV === 'development' ? 'http://localhost:3000/map.html' : 'https://woosint.de/dpa-fc-challenge/map.html'}
            width="90%"
            height="470px"
          />
        </>
      );
    case "multiple":
      return (
        <>
          <p className="input">
            <select
              id={currentQuestion.id}
              className="multiple"
              onChange={props.onChange}
              name="multiple"
              defaultValue={"default"}
            >
              <option disabled value={"default"}> -- Option auswählen -- </option>
              {currentQuestion.options.map((choice, index) => {
                return (
                  <option
                    className="multiple"
                    value={choice}
                    key={index}
                    id={currentQuestion.name}
                  >
                    {choice}
                  </option>
                );
              })}
            </select>
          </p>
        </>
      );
    default:
      return (
        <>
          <p className="paragraph">Kein gültiger Fragentyp</p>
        </>
      );
  }
};

export default InputComponent;

import clsx from "clsx";
import React, { useState } from "react";
import { isUserInputDigits } from "../../utils/disableButtonLogic";
import "./legal.css";

export default function Legal() {
  const [formValues, setFormValues] = useState({
    userId: "",
    acceptedTandC: false,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [apiResponse, setApiResponse] = useState([]);
  const [succes, setSucces] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    fetch(
      `https://bvh8nqmuxa.execute-api.eu-central-1.amazonaws.com/dev/score/${formValues.userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "aplication/json",
        },
      }
    )
      .then((response) => {
        return response.text();
      })
      .then((response) => {
        setLoading(false);
        if (JSON.parse(response).answers.length > 0) {
          setApiResponse(JSON.parse(response).answers);
        } else {
          setError("Für diese Ticketnummer sind leider keine Ergebnisse verfügbar");
        }
      })
      .catch((e) => {
        setLoading(false);
        setError(e);
        console.log("error: ", e);
      });
  };

  const onFinalSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    fetch(`https://bvh8nqmuxa.execute-api.eu-central-1.amazonaws.com/dev/reg`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: formValues.userId,
      }),
    })
      .then((response) => {
        return response.text();
      })
      .then((response) => {
        if (response.status !== 200) {
          setError(JSON.parse(response).message);
        } else {
          setSucces(true);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setError(e);
        console.log("error: ", e);
      });
  };

  const disableFinalSubmitButton = () => {
    if (apiResponse.length === 0) {
      return true;
    }
    if (!formValues.acceptedTandC) {
      return true;
    }
    return false;
  };

  return (
    <div className="App">
      <header className="App-header">
        <div
          className={clsx("bg_box", loading && "blurBackground")}
          id="questions"
        >
          <h1>Gewinnspiel</h1>
          <>
            {apiResponse.length === 0 && (
              <>
                <p className="paragraph">
                Um am Gewinnspiel teilnehmen zu können, musst du deine
                  Ticketnummer mit einer E-Mail-Adresse verbinden. Bitte
                  beachte, dass du <b>mindestens eine Frage beantwortet</b>{" "}
                  haben musst, bevor du dich für das Gewinnspiel anmelden
                  kannst.
                </p>

                <form onSubmit={onSubmit}>
                  <div className="input-ticket">
                    <input
                      value={
                        apiResponse.length > 0
                          ? apiResponse[0].userId
                          : formValues.userId
                      }
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          userId: e.target.value,
                        })
                      }
                      type="text"
                      id="text"
                      className="ticket"
                      name="userID"
                      disabled={apiResponse.length > 0}
                    />
                    <button
                      className={clsx(
                        "btn-ticket",
                        !isUserInputDigits(formValues) && "btn-ticket-disabled"
                      )}
                      type="submit"
                      disabled={!isUserInputDigits(formValues.userId)}
                    >
                      <div className="ticket-text-request">Absenden</div>
                    </button>
                  </div>
                </form>
              </>
            )}
            {apiResponse.length > 0 && (
              <>
                <p className="paragraph">
                  Damit wir Dich über einen möglichen Gewinn informieren können,
                  brauchen wir noch Deine E-Mail-Adresse und die Bestätigung,
                  dass Du die Teilnahmebedingungen gelesen hast und akzeptierst.
                </p>
                <form onSubmit={onFinalSubmit} className="legal">
                  <table className="legal-table">
                    <tr>
                      <td>Ticketnummer</td>
                      <td>{apiResponse[0].userId}</td>
                    </tr>
                    <tr>
                      <td className="legal-tc">
                        <label>
                          <a
                            href="https://www.dpa.com/de/faktencheck23#teilnahmebedingungen"
                            rel="noreferrer"
                            target="_blank"
                          >
                            Teilnahmebedingungen
                          </a>{" "}
                          gelesen & akzeptiert
                        </label>
                      </td>
                      <td>
                        <input
                          value={formValues.acceptedTandC}
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              acceptedTandC: !formValues.acceptedTandC,
                            })
                          }
                          type="checkbox"
                          id="checkbox"
                          className="checkbox"
                          name="checkbox"
                        />
                      </td>
                    </tr>
                  </table>
                  <p className="input">
                    <button
                      className={clsx(
                        "btn",
                        disableFinalSubmitButton() && "btn-disabled"
                      )}
                      type="submit"
                      disabled={disableFinalSubmitButton()}
                    >
                      Weiter
                    </button>
                  </p>
                </form>
              </>
            )}

            {loading && (
              <div className="loadingState">
                <div className="loading-dots">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            )}
            {!succes && error.length > 0 && (
              <div className="resultsTableWrapper">
                <p className="error">{error}</p>
              </div>
            )}

            {succes && <p>Your Email and UserID are now connected</p>}
          </>
        </div>
      </header>
    </div>
  );
}

export const defineDefaultValue = (defaultValue, min, max) => {
    if (defaultValue === "date") {
      let yourDate = new Date();
      return yourDate.toISOString().split("T")[0];
    }
    if (defaultValue === "range") {
      return ((max + min) / 2).toString();
    }
    if (defaultValue === "time") {
      return "00:00";
    }
    if (defaultValue === "geoguesser") {
      return [0, 0];
    }
    return defaultValue;
  };